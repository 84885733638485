








import {Component, Prop, Vue} from 'vue-property-decorator';
import {Tag} from "@/types";
import UButton from '@/ui-lib/button/Button.vue';
import {TagDeleteModalParams} from "@/components/modals/TagDeleteModal.vue";

@Component({
  components: {
    UButton,
  }
})
export default class TagFormView extends Vue {
  @Prop({required: false})
  private tag!: Tag;

  private async onSubmit() {
    const params: TagDeleteModalParams = {
      tag: this.tag,
      onDeleted: () => {
        this.$emit('deleted');
      }
    }

    this.$modal.show('tag-delete', params);
  }
}
